<template>
  <div>
    <v-row class="">
      <v-col cols="6" sm="6"> </v-col>
      <v-col class="text-right" v-if="is_imployee" cols="6" sm="6">
        <v-btn class="modal-btn-save" to="/leaverequest">{{
          $t("Requests")
        }}</v-btn>
      </v-col>
    </v-row>
    <div class="wrapperDiv">
      <h1>
        <span
          >{{ $t("WELCO") }} <i class="far fa-smile-beam"></i>
          {{ $t("ME") }}</span
        >
      </h1>
      <h3>
        <span>{{ $t("TO") }} </span>
        <span>{{ $t("SCHOOL MANAGEMENT SYSTEM") }}</span>
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      is_imployee: false
    };
  },
  mounted() {
    if (localStorage.is_employee == "true") {
      this.is_imployee = true;
    }
  }
};
</script>

<style lang="css" scoped>
.wrapperDiv {
  text-align: center;
  margin-top: 200px;
}
h1 {
  font-size: 150px;
  color: #7295fb;
}
h1 .far {
  font-size: 110px;
  margin: 0px 8px;
}
h1 span {
  border-bottom: 1px solid;
}
h1,
h3 {
  margin-bottom: 0;
  font-family: sans-serif;
}
h3 {
  color: #676767;
  font-size: 44px;
}
img {
  color: #7295fb;
}
</style>
